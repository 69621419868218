<template>
  <footer
    class="border-t-2 border-t-gray-200 border-double z-50 py-0 bg-white w-full"
  >
    <SolutionsFooter />
    <div class="w-full px-4 py-4">
      <div class="flex justify-between items-center">
        <!-- Logo on the left -->
        <div class="flex-shrink-0 mx-2" style="float: left">
          <img
            src="/css/hochfrequenz/logo_weiss.png"
            alt="Hochfrequenz Logo"
            style="width: 160px; height: auto"
            class="w-40 h-auto"
          />
        </div>

        <!-- Menu items on the right -->
        <div class="flex items-center">
          <p class="space-x-2 flex flex-wrap items-center m-0">
            <span>© {{ new Date().getFullYear() }} -</span>
            <DeploymentInfo class="inline-flex items-center" /> -
            <span>made with</span>
            <i class="mdi mdi-heart-outline text-sm align-middle"></i>
            <span>by</span>
            <a
              target="_blank"
              href="https://www.hochfrequenz.de"
              class="hover:text-gray-500 transition-colors duration-200 px-1"
              style="font-weight: 800"
              >Hochfrequenz</a
            >
            <span>| powered by</span>
            <a
              href="https://github.com/hochfrequenz/ahbicht"
              class="hover:text-gray-500 transition-colors duration-200 px-1"
              style="font-weight: 800"
              >AHB_icht</a
            >
            <span>&amp;</span>
            <a
              href="https://github.com/hochfrequenz/kohlrahbi"
              class="hover:text-gray-500 transition-colors duration-200 px-1"
              style="font-weight: 800"
              >kohlr_AHB_i</a
            >
            <span>|</span>
            <a
              target="_blank"
              href="https://www.hochfrequenz.de/datenschutz/"
              class="footer-link text-[#3b4045] !important transition-colors duration-200 px-1"
              >Datenschutz</a
            >
            <span>|</span>
            <a
              target="_blank"
              href="https://www.hochfrequenz.de/impressum/"
              class="footer-link text-[#3b4045] !important transition-colors duration-200 px-1"
              >Impressum</a
            >
            <span>|</span>
            <a
              target="_blank"
              href="https://www.hochfrequenz.de/kontakt/"
              class="footer-link text-[#3b4045] !important transition-colors duration-200 px-1"
              >Kontakt</a
            >
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import DeploymentInfo from "@/components/DeploymentInfo.vue";
import SolutionsFooter from "@/components/UpperFooter.vue";

export default {
  name: "AppFooter",
  components: {
    SolutionsFooter,
    DeploymentInfo,
  },
};
</script>

<style scoped>
.footer-link {
  color: #3b4045 !important;
}
</style>
