import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

function getCssVariable(variableName: string): string {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variableName)
    .trim();
}

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: getCssVariable("--grell-gelb"), // darker yellow
        secondary: getCssVariable("--pastell-gelb"), // lighter yellow
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
      dark: {
        primary: getCssVariable("--grell-gelb"), // darker yellow
        secondary: getCssVariable("--pastell-gelb"), // lighter yellow
      },
    },
  },
});
