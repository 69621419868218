import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authenticationGuard } from "@/auth/authGuard";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "StartPage",
    component: () => import("../views/StartPage.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/tree/:hash",
    name: "Home",
    props: true,
    component: () => import("../views/Home.vue"),
    beforeEnter: authenticationGuard,
  },
  {
    path: "/tree/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    beforeEnter: authenticationGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
