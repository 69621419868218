<template>
  <div class="navbar-default navbar-fixed-top w-full" id="navigation">
    <div class="container-fluid w-full px-4">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-header">
        <a class="navbar-brand" href="/#body">
          <img
            src="/css/hochfrequenz/symbol_weiss.png"
            alt="HF Logo"
            class="navbar-logo"
          />
          <span class="text-xl ml-3">Bedingungsbaum</span>
        </a>
      </div>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <nav class="navbar-collapse" id="navbar">
        <ul class="nav navbar-nav navbar-right" id="top-nav">
          <li v-if="$auth.isAuthenticated && $auth.loading === false">
            <div @click="logout" class="rounded-pill pill-button">
              Ausloggen
              <v-icon class="ml-1">mdi-logout</v-icon>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

import { Component } from "vue-property-decorator";
// import LoadingDialog from "./components/LoadingDialog.vue";

@Component({
  name: "Navbar",
  components: {},
  props: [],
})
export default class Navbar extends Vue {
  public loading = false;
  public showProgressBar() {
    this.loading = true;
    setTimeout(() => (this.loading = false), 2000);
  }

  public login() {
    this.$auth.loginWithRedirect({});
  }

  public logout() {
    this.$auth.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }
}
</script>

<style>
#navigation.navbar-default {
  background-color: var(--grell-gelb) !important;
  display: flex;
  align-items: center;
  min-height: 70px;
}

#navigation .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-header {
  display: flex;
  align-items: center;
  height: 70px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  text-decoration: none;
  color: var(--weiches-schwarz) !important;
  font-size: 1.25rem;
}

.navbar-logo {
  height: 24px;
  width: auto;
}

#navigation .navbar-collapse {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

#navigation .navbar-nav {
  display: flex;
  align-items: center;
  margin: 0;
  height: 70px;
}

#navigation .navbar-nav > li {
  display: flex;
  align-items: center;
  height: 100%;
}

#navigation .pill-button {
  background-color: var(--pastell-gelb);
  color: var(--weiches-schwarz) !important;
  font-weight: 500;
  padding: 8px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

#navigation .pill-button:hover {
  background-color: var(--pastell-gelb);
  opacity: 0.9;
  transform: translateY(-1px);
  color: var(--weiches-schwarz) !important;
}
</style>
