import axios from "axios";
import {
  CategorizedKeyExtract,
  AhbExpressionEvaluationResult,
  ContentEvaluationResult,
  PackageKeyConditionExpressionMapping,
} from "ahbicht-ts-models";
import { ServiceBase } from "./ServiceBase";

interface ConditionSuccessResponse {
  edifact_format: string;
  condition_key: string;
  condition_text: string;
}

interface ConditionErrorResponse {
  error: string;
  code: number;
}
export default interface ExpressionTree {
  expressionTree: Record<string, unknown>;
}

export type EdifactFormatVersion =
  | "FV2210"
  | "FV2304"
  | "FV2310"
  | "FV2404"
  | "FV2410"
  | "FV2504";

export interface EdifactFormatVersionData {
  text: string; // e.g. "Oktober 2025"
  value: EdifactFormatVersion | null; // e.g. "FV2510"
  keydate: Date; // e.g. 2025-09-30T22:00:00Z
}

export const AvailableEdifactFormatVersions: EdifactFormatVersionData[] = [
  { text: "Keine Angabe", value: null, keydate: new Date(1990, 0, 1) },
  { text: "April 2025", value: "FV2504", keydate: new Date(2025, 3, 4) },
  { text: "Oktober 2024", value: "FV2410", keydate: new Date(2024, 9, 1) },
  { text: "April 2024", value: "FV2404", keydate: new Date(2024, 3, 3) },
  { text: "Oktober 2023", value: "FV2310", keydate: new Date(2023, 9, 1) },
  { text: "April 2023", value: "FV2304", keydate: new Date(2023, 3, 1) }, // and 3 represents April
  { text: "Oktober 2022", value: "FV2210", keydate: new Date(2022, 9, 1) }, // Month is 0-indexed in JS, so 9 represents October 🤯
];

export type EdifactFormat =
  | "APERAK"
  | "COMDIS"
  | "CONTRL"
  | "IFTSTA"
  | "INSRPT"
  | "INVOIC"
  | "MSCONS"
  | "ORDCHG"
  | "ORDERS"
  | "ORDRSP"
  | "PARTIN"
  | "PRICAT"
  | "QUOTES"
  | "REMADV"
  | "REQOTE"
  | "UTILMD"
  | "UTILTS";

export interface EdifactFormatData {
  text: string; // e.g. "UTILMD" or "Stammdaten"
  value: EdifactFormat | null; // e.g. "UTILMD"
}

export const AvailableEdifactFormats: EdifactFormatData[] = [
  { text: "Keine Angabe", value: null },
  { text: "APERAK", value: "APERAK" },
  { text: "COMDIS", value: "COMDIS" },
  { text: "CONTRL", value: "CONTRL" },
  { text: "COMDIS", value: "COMDIS" },
  { text: "IFTSTA", value: "IFTSTA" },
  { text: "INSRPT", value: "INSRPT" },
  { text: "INVOIC", value: "INVOIC" },
  { text: "MSCONS", value: "MSCONS" },
  { text: "ORDCHG", value: "ORDCHG" },
  { text: "ORDERS", value: "ORDERS" },
  { text: "ORDRSP", value: "ORDRSP" },
  { text: "ORDRSP", value: "ORDERS" },
  { text: "PARTIN", value: "PARTIN" },
  { text: "PRICAT", value: "PRICAT" },
  { text: "QUOTES", value: "QUOTES" },
  { text: "REMADV", value: "REMADV" },
  { text: "REQOTE", value: "REQOTE" },
  { text: "UTILMD", value: "UTILMD" },
  { text: "UTILTS", value: "UTILTS" },
];

export default class ExpressionService extends ServiceBase {
  [x: string]: any;

  private errorTolerantAxiosInstance = axios.create({
    validateStatus: function () {
      // This is an axios instance, that does not throw an error if the HTTP status code (in the response header)
      // is negative.
      // This is necessary because (my best guess), since the upgrade of the backend from Azure Function 3 to version 4,
      // the backend returns a negative status code if an error occurs.
      // Before that, it only set the code in the response body but sent a positive status code in the header.
      // This didn't matter in the build which we deployed online, but it makes a huge difference while debugging
      // locally, because any uncaught error blocks the screen from rendering and effectively freezes the app.
      return true;
    },
  });

  public static getCurrentEdifactFormatVersionData(): EdifactFormatVersionData {
    const now = new Date();
    const pastDates = AvailableEdifactFormatVersions.filter(
      (item: EdifactFormatVersionData) => item.keydate < now,
    ).sort(
      (a: EdifactFormatVersionData, b: EdifactFormatVersionData) =>
        b.keydate.getTime() - a.keydate.getTime(),
    );
    const result =
      pastDates.length > 0 ? pastDates[0] : AvailableEdifactFormatVersions[0];
    return result;
  }

  public async getExpressionTree(
    expression: string,
    format_version: EdifactFormatVersionData,
    format: EdifactFormatData,
  ): Promise<ExpressionTree> {
    const effective_format: EdifactFormat | null = format.value ?? null;
    const effective_format_version: EdifactFormatVersion | null =
      format_version.value ?? null;
    try {
      const res = await this.errorTolerantAxiosInstance.get(
        `${this.baseUrl}/ParseExpression`,
        {
          params: {
            expression,
            concise: true,
            format: effective_format ?? "",
            format_version: effective_format_version ?? "",
          },
        },
      );
      res.data = this.removeSingleMarkBeginningNode(res.data);
      return res.data;
    } catch (err) {
      console.log("error: -> ", err);
      throw err;
    }
  }

  //removes the first model mark node (e.g. "Muss" or "Kann") iff there is only one
  public removeSingleMarkBeginningNode(tree: any) {
    if (
      tree["children"] &&
      tree["children"][0]["tree"]["children"] &&
      tree["children"].length == 1
    ) {
      tree["children"] = tree["children"][0]["tree"]["children"];
    }
    return tree;
  }

  public async getConditionText(
    conditionNumber: number,
    edifactFormatVersion: EdifactFormatVersion,
    edifactFormat: EdifactFormat,
  ): Promise<string> {
    try {
      const res = await this.errorTolerantAxiosInstance.get<
        ConditionSuccessResponse | ConditionErrorResponse
      >(
        `${this.baseUrl}/ResolveConditionText/${edifactFormatVersion}/${edifactFormat}/${conditionNumber}`,
      );

      // Check if response is an error response
      if ("error" in res.data) {
        // Return 'nicht vorhanden' string for 404
        if (res.data.code === 404) {
          return "nicht vorhanden";
        }
        throw new Error(res.data.error);
      }

      return res.data.condition_text || "";
    } catch (err) {
      console.error(
        `Failed to fetch condition text for condition ${conditionNumber}:`,
        err,
      );
      return "";
    }
  }

  public async getPackageConditionExpression(
    packageKey: string,
    edifactFormatVersion: EdifactFormatVersion,
    edifactFormat: EdifactFormat,
  ): Promise<PackageKeyConditionExpressionMapping> {
    const res = await this.errorTolerantAxiosInstance.get(
      `${this.baseUrl}/ResolvePackageConditionExpression/${edifactFormatVersion}/${edifactFormat}/${packageKey}`,
    );
    return res.data;
  }

  public async evaluateCondition(
    contentEvaluationResult: ContentEvaluationResult,
    expression: string,
  ): Promise<AhbExpressionEvaluationResult> {
    try {
      const res = await this.errorTolerantAxiosInstance.post(
        `${this.baseUrl}/ParseExpression`,
        contentEvaluationResult,
        {
          params: { expression },
        },
      );

      return res.data;
    } catch (err) {
      // Handle Error Here
      console.error(err);
      throw err;
    }
  }

  public async getCategorizedKeyExtract(
    expression: string,
  ): Promise<CategorizedKeyExtract> {
    try {
      const res = await this.errorTolerantAxiosInstance.get(
        `${this.baseUrl}/CategorizedKeyExtract`,
        {
          params: { expression },
        },
      );
      return res.data;
    } catch (err) {
      console.log("error:", err);
      throw err;
    }
  }

  // Fetch suggestions from the backend and returns them
  public async fetchSuggestionsFromBackend(
    input: string,
    format_version: EdifactFormatVersionData,
    format: EdifactFormatData,
  ): Promise<string[]> {
    if (input) {
      const effective_format: EdifactFormat | null = format.value ?? null;
      const effective_format_version: EdifactFormatVersion | null =
        format_version.value ?? null;
      try {
        const response = await this.errorTolerantAxiosInstance.get(
          `${this.baseUrl}/SuggestExpression`,
          {
            params: {
              expression: input,
              format: effective_format ?? "",
              format_version: effective_format_version ?? "",
            },
          },
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        return [];
      }
    } else {
      return [];
    }
  }
}

export class TreeData {
  constructor(
    expression: string = "",
    expressionTree: ExpressionTree = new ExpressionTree(),
    selectedEdifactFormat: EdifactFormatData = AvailableEdifactFormats[0],
    selectedEdifactFormatVersion: EdifactFormatVersionData = AvailableEdifactFormatVersions[0],
  ) {
    this.expression = expression;
    this.expressionTree = expressionTree;
    this.selectedEdifactFormat = selectedEdifactFormat;
    this.selectedEdifactFormatVersion = selectedEdifactFormatVersion;
  }
  public expression;
  public expressionTree;
  public selectedEdifactFormat;
  public selectedEdifactFormatVersion;
}
