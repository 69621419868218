<template>
  <span
    v-if="isProduction"
    class="info-text"
    :title="`Build Datum: ` + formattedBuildDate"
  >
    Version: {{ version }}
  </span>
  <div v-else class="staging-info">
    <a
      :href="commitUrl"
      target="_blank"
      rel="noopener noreferrer"
      class="info-link"
    >
      Commit: {{ shortCommitHash }}
    </a>
    <span class="build-date">(Build: {{ formattedBuildDate }})</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

// Environment variables
const version = process.env.VUE_APP_RELEASE_VERSION || "N/A";
const commitHash = process.env.VUE_APP_COMMIT_ID || "N/A";
const githubLink = process.env.VUE_APP_GITHUB_LINK || "#";
const buildDate = process.env.VUE_APP_BUILD_DATE || "N/A";

// Computed properties
const isProduction = computed(() => {
  return version !== "N/A" && version !== "";
});

const shortCommitHash = computed(() => {
  return commitHash.slice(0, 7);
});

const commitUrl = computed(() => {
  return githubLink !== "#" ? githubLink : "#";
});

const formattedBuildDate = computed(() => {
  if (buildDate === "N/A") return "Nicht verfügbar auf localhost";
  try {
    const date = new Date(buildDate);
    return date.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  } catch {
    return buildDate;
  }
});
</script>

<style scoped>
.deployment-info {
  font-size: 0.875rem;
  color: #666;
}

.staging-info {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
}

.info-link {
  color: #666;
  text-decoration: none;
}

.info-link:hover {
  text-decoration: underline;
}

.build-date {
  color: #888;
}
</style>
