<template>
  <div id="solutions" class="w-full ma-0 flex">
    <a class="ahbesser" href="https://ahb-tabellen.hochfrequenz.de"
      >AHB-Tabellen</a
    >
    <a class="fristenkalender" href="https://fristenkalender.hochfrequenz.de"
      >Fristenkalender</a
    >
    <a class="ahahnb" href="https://bedingungsbaum.hochfrequenz.de"
      >Bedingungsbaum</a
    >
    <a class="entscheidungsbaum" href="https://ebd.hochfrequenz.de"
      >Entscheidungsbaumdiagramm</a
    >
  </div>
</template>

<script>
export default {
  name: "SolutionsFooter",
};
</script>
